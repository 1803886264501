.acc-container {
  background: url(../../../assets/images/background.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; 
}

:root {
  --loader-bar-bg: white;
  --loader-bar-width: 2vmin;
}


.loader {
  position: relative;
  width: 20vmin;
  aspect-ratio: 1;
  background: conic-gradient(
    transparent,
    transparent 12%,
    var(--loader-bar-bg)
  );
  border-radius: 50%;
  margin: auto;
  animation: spin 1.1s linear infinite;

  &::before,
  &::after {
    content: "";
    position: absolute;
    border-radius: inherit;
  }

  &::before {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(
      to left,
      var(--loader-bar-bg) 50%,
      transparent 50%
    );
    width: var(--loader-bar-width);
    aspect-ratio: 1;
  }

  &::after {
    inset: var(--loader-bar-width);
    background: transparent;
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}