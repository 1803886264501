.section3 {
  background: #B6B6B621;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section3_innerdiv {
  display: flex;
  justify-content: space-between;
}

.section3_rightdiv {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.herosection_item {

  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  opacity: 0;
}

.herosection_item.left {
  transform: translateX(-100%);
}

.herosection_item.right {
  transform: translateX(100%);
}

.herosection_item.loaded {
  transform: translateX(0);
  opacity: 1;
}

.getstarted {
  
  /* background: #04A287; */
  background: linear-gradient(to right, white 50%, #04A287 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .5s ease-out;


}

.getstarted:hover {
  background-position: left bottom;
}

.parallax {
  /* The image used */
  background-image: url("/public/images/section3.png");
  min-height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.word {
  display: inline-block;
  /* animation: changeWord 2s infinite; */
  transition: opacity 0.5s ease-in-out, color 0.5s ease-in-out;
  /* Added color transition */
}

.linear-gradient {
  background: linear-gradient(1360deg, rgba(4, 162, 135, 1) 26%, rgba(38, 145, 215, 1) 100%)
}

.custom-shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.custom-border {
  border: 1px solid #D1D1D1;
}

.custom-vertical-line {
  width: 2px;
  height: 85px;
  background: white;
}

/* .marquee  */
@keyframes changeWord {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  10% {
    opacity: 1;
    transform: translateY(0);
  }

  90% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}


.marquee {
  display: inline-flex;
  animation: marquee 20s linear infinite;
}
@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}