@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="text"]:focus ~ .input-text,
input[type="text"]:not(:placeholder-shown) ~ .input-text {
  @apply text-blue-500 transform -translate-y-10 -translate-x-2 scale-75;
}

.option-styling {
  padding: 0.5rem 1rem; /* Increase padding */
}
.option-styling:hover {
  background-color: #e5e7eb; /* Change hover color */
}

/* page loader */
:root {
  --loader-bar-bg: darkcyan;
  --loader-bar-width: 2vmin;
}


.loader {
  position: relative;
  width: 20vmin;
  aspect-ratio: 1;
  background: conic-gradient(
    transparent,
    transparent 12%,
    var(--loader-bar-bg)
  );
  border-radius: 50%;
  margin: auto;
  animation: spin 1.1s linear infinite;

  &::before,
  &::after {
    content: "";
    position: absolute;
    border-radius: inherit;
  }

  &::before {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(
      to left,
      var(--loader-bar-bg) 50%,
      transparent 50%
    );
    width: var(--loader-bar-width);
    aspect-ratio: 1;
  }

  &::after {
    inset: var(--loader-bar-width);
    background: white;
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
