.key-features {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 5rem;
}

.heading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.heading-line {
  flex: 1;
  height: 1px;
}

.heading-line.left {
  background: linear-gradient(
    to right,
    rgba(4,162,135,1),
    rgba(4,162,135,0)
  );
}

.heading-line.right {
  background: linear-gradient(
    to left,
    rgba(4,162,135,1),
    rgba(4,162,135,0)
  );
}

.heading-text {
  font-size: 2rem; /* Default size for larger screens */
  font-weight: bold;
  text-align: center;
  margin: 0 1rem;
  padding: 0.5rem;
}

.desktop-heading {
  display: none; /* Hide on small screens */
}

.mobile-heading {
  display: block; /* Show on small screens */
}

/* Responsive Design */
@media (min-width: 768px) {
  .desktop-heading {
    display: block; /* Show on larger screens */
  }

  .mobile-heading {
    display: none; /* Hide on larger screens */
  }

  .heading-text {
    font-size: 2.5rem; /* Adjust size for larger screens */
  }

  .heading-line {
    height: 4px; /* Adjust height for larger screens */
  }
}

/* Grid layout for screen widths between 1024px and 2500px */
@media (min-width: 1024px) and (max-width: 2500px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 5rem;
    align-items: center;
  }

  .card {
    background-color: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(127, 210, 196, 0.3), /* Right and bottom shadow */
                0 -4px 6px rgba(127, 210, 196, 0.3), /* Left and top shadow */
                0 0 6px rgba(127, 210, 196, 0.3);    /* Spread shadow */
    text-align: left;
  }

  .first {
    grid-column: 1 / span 1;
    grid-row: 1 / 2;
  }

  .second {
    grid-column: 1 / span 1;
    grid-row: 2 / 3;
  }

  .middle {
    grid-column: 2 / span 1;
    grid-row: 1 / 3;
    align-self: center;
  }

  .fourth {
    grid-column: 3 / span 1;
    grid-row: 1 / 2;
  }

  .fifth {
    grid-column: 3 / span 1;
    grid-row: 2 / 3;
  }
}

/* Center cards on very small screens */
/* Grid container for screen widths up to 1023px */
@media (max-width: 1023px) {
  .key-features {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 1rem;
    justify-content: center; /* Center grid items horizontally */
    align-items: center; /* Center grid items vertically */
  }

  .card {
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(127, 210, 196, 0.3), /* Right and bottom shadow */
                0 -4px 6px rgba(127, 210, 196, 0.3), /* Left and top shadow */
                0 0 6px rgba(127, 210, 196, 0.3);    /* Spread shadow */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
    justify-content: center; /* Center content vertically */
    width: calc(100%); /* Adjust width to fit within small screens */
    margin: 0 auto; /* Center the card horizontally */
  }

  .card img {
    max-width: 80%; /* Ensure image scales appropriately */
    height: auto;
    margin-bottom: 0.5rem; /* Space between image and heading */
  }

  .card h2, /* Assuming heading is an <h2> element */
  .card h3 { /* Adjust if using other heading elements */
    margin: 0; /* Remove default margin */
    font-size: 1.25rem; /* Adjust font size for small screens */
    line-height: 1.4; /* Improve readability */
  }

  .card p { /* Ensure text within the card is readable */
    font-size: 0.875rem; /* Smaller font size for better fit */
    line-height: 1.5;
  }
}

.container {
  max-width: 100%; /* Adjust container width to fit small screens */
  margin: 0 auto;
  text-align: center;
  padding: 1rem; /* Add padding around container */
}

.section-title {
  font-size: 1.5rem; /* Smaller font size for small screens */
  margin-bottom: 1rem;
  color: #01AEB7;
}

.view-all-btn {
  margin-top: 1rem; /* Adjust margin for small screens */
  padding: 0.5rem 1.5rem; /* Adjust padding for small screens */
  background-color: #04A287;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem; /* Smaller font size for small screens */
}
