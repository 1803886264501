@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="text"]:focus ~ .input-text,
input[type="text"]:not(:placeholder-shown) ~ .input-text{
  @apply text-blue-500 transform -translate-y-10 -translate-x-2 scale-75;
}

.option-styling {
  padding: 0.5rem 1rem; /* Increase padding */
}
.option-styling:hover {
  background-color: #e5e7eb; /* Change hover color */
}