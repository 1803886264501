body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: url(./assets/images/bg1.jpeg); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-image: linear-gradient(
  170deg,
  hsl(166deg 61% 55%) 0%,
  hsl(172deg 65% 48%) 35%,
  hsl(177deg 100% 38%) 52%,
  hsl(180deg 100% 36%) 64%,
  hsl(184deg 100% 36%) 73%,
  hsl(186deg 100% 36%) 80%,
  hsl(189deg 100% 36%) 86%,
  hsl(191deg 100% 35%) 91%,
  hsl(193deg 100% 34%) 94%,
  hsl(196deg 82% 36%) 98%,
  hsl(201deg 62% 38%) 100%
); */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


