.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

/* Modal content */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow effect */
  max-width: 400px;
  width: 100%;
}

/* Close button */
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.active-nav {
  border-bottom: 3px solid rgb(30, 123, 30);
}

/* CSS for the ribbon */
.ribbon-container {
  position: relative; /* Needed to position the ribbon */
}

.ribbon-container::before {
  content: attr(data-label); /* Use the data-label as the ribbon content */
  position: absolute;
  top: 10px;
  left: -59px;
  background-color: #4779ff; /* Adjust the ribbon color */
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  transform: rotate(-45deg);
  transform-origin: 0 0;
  z-index: 1; /* Ensure the ribbon is above other elements */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
