@media screen and (-webkit-min-device-pixel-ratio: 0) {
     
    input[type="range"]::-webkit-slider-thumb {
        width: 18px;
        -webkit-appearance: none;
          appearance: none;
        height: 18px;
        border: 1px solid #878787;
        cursor: ew-resize;
        background: #FFF;
        box-shadow: -405px 0 0 400px #22ACCA;
        border-radius: 50%;
        
    }
}